import { render, staticRenderFns } from "./Moments.vue?vue&type=template&id=9dfd23c6&scoped=true&"
import script from "./Moments.vue?vue&type=script&lang=js&"
export * from "./Moments.vue?vue&type=script&lang=js&"
import style0 from "./Moments.vue?vue&type=style&index=0&id=9dfd23c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dfd23c6",
  null
  
)

export default component.exports