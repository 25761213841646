<template>
  <div>
    <el-card v-for="item in testList" style="animation: article 1s;margin-bottom: 18px;">
      <div class="moment-item">
        <div class="moment-item-wrap">
          <!--头像-->
          <div class="uer-info-1">
            <el-avatar :size="50" class="moment-avatar" icon="el-icon-user-solid"
                       src="https://q2.qlogo.cn/headimg_dl?dst_uin=1348188006&spec=100"></el-avatar>
          </div>
          <!--昵称、内容、日期、回复按钮-->
          <div class="uer-info-2">
            <!--接口获取的昵称中包含标点符号为HTML字符实体，使用v-html才能正常展示数据-->
            <div class="user-name" v-html="'岁月的史书'"></div>
            <div class="user-data">
              <span style="margin-right: 5px">{{ "两小时前" }}</span>
              <span>{{ "来自 Xiaomi 12" }}</span>
            </div>
            <div class="user-moment">{{
                "本期讲座邀请多位资深算法专家，和大家聊聊“魔搭”社区的“明星”模型的研发故事，包括AIGC文本生成图片，智能语音交互，基础计算机视觉在内的各种模态的模型生态。AIGC是继 UGC、PGC 之后利用AI技术自动生成内容的新型生产方式，近几年迭代速度呈现爆发趋势。AIGC背后的大模型是如何发展的？届时我们会在直播中与大家一同探讨，感兴趣的小伙伴们不要错过啦"
              }}
            </div>
          </div>
        </div>
        <div class="moment-toolbar">
            <div class="moment-toolbar-item">
              <i class="fa-regular fa-arrow-up-right-from-square"></i>
              <span>{{ "123" }}</span>
            </div>
            <div class="moment-toolbar-item">
              <i class="fa-regular fa-message-dots"></i>
              <span>{{ "123" }}</span>
            </div>
            <div class="moment-toolbar-item">
              <i class="fa-regular fa-thumbs-up"></i>
              <span>{{ "123" }}</span>
            </div>
        </div>
      </div>
    </el-card>
  </div>

</template>

<script>
export default {
  name: "Moments",
  data() {
    return {
      testList: [1, 2]
    }
  }
}
</script>

<style scoped>

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgba(59, 241, 213, 0.8)
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(59, 241, 213, 0.8);
  color: #fff
}

.moment-item {
  padding: 20px 50px 0 50px;
}

.user-name {
  margin-bottom: 5px;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 700;
  line-height: 22px;
}

.moment-avatar {
  margin-right: 15px;
}

.user-moment {
  margin: 10px 0;
  padding-right: 35px;
  line-height: 24px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.uer-info-1 {
}

.uer-info-2 {

}

.user-data {
  color: rgb(148, 153, 160);
  font-size: 13px;
}

.moment-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.moment-item-wrap {
  display: flex;
  flex-direction: row;
  /*用于提交评论后新评论插入的过渡动画*/
  animation: moment-item-wrap 0.8s;
}

.moment-toolbar-item span {
  font-size: 12px;
  padding-left: 7px;;
}

.moment-toolbar-item {
  display: flex;
  width: 100%;
  transition: all .2s;
  justify-content: center;
}

.moment-toolbar-item:hover {
  color: #49b1f5;
}

</style>